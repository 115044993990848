import Model from './Model.js';

import ClientAccountModel from './ClientAccountModel.js';

class UserModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('clientAccount', ClientAccountModel, true);
        this.prop('role', Number, false);
        this.prop('permissions', String, true);
        this.prop('isDriver', Boolean, false);
        this.prop('firstName', String, true);
        this.prop('lastName', String, true);
        this.prop('email', String, true);
        this.prop('workPhone', String, false);
        this.prop('cellPhone', String, false);
        this.prop('isDeleted', Boolean, false);

        this.populate(values, config);

    }
    static name = 'UserModel';
    static source = 'User';
}
window[Symbol.for(UserModel.name)] = UserModel;

export default UserModel;