<template>
    <div class="user-create content">
        <form>
            <h2>Add new user</h2>
            <div class="form-group inline-group">
                <label>
                    First name
                    <input type="text" />
                </label>
                <label>
                    Last name
                    <input type="text" />
                </label>
            </div>
            <div class="form-group">
                <label>
                    Email
                    <input type="text" />
                </label>
            </div>
            <div class="form-group">
                <label>
                    User role
                    <select>
                        <option>Star user</option>
                        <option>Star admin</option>
                    </select>
                </label>
            </div>
            <!-- <h3>Permissions</h3>
            <div class="layout-split checkbox-group">
                <div class="form-group">
                    <label>
                        <input type="checkbox" checked="checked" /><span>Bulk Upload</span>                    
                    </label>
                    <label>
                        <input type="checkbox" checked="checked" /><span>Fleet Dashboard</span>                    
                    </label>
                    <label>
                        <input type="checkbox" checked="checked" /><span>Equipment – File Upload</span>                    
                    </label>
                    <label>
                        <input type="checkbox" checked="checked" /><span>Billing</span>                    
                    </label>
                    <label>
                        <input type="checkbox" checked="checked" /><span>Data &amp; Reports</span>                    
                    </label>
                </div>
                <div class="form-group">
                    <label>
                        <input type="checkbox" checked="checked" /><span>Manage Customer Users</span>                    
                    </label>
                    <label>
                        <input type="checkbox" checked="checked" /><span>Contracts</span>                    
                    </label>
                    <label>
                        <input type="checkbox" /><span>SBS – File Upload</span>                    
                    </label>
                    <label>
                        <input type="checkbox" /><span>SBS – File Delete</span>                    
                    </label>
                    <label>
                        <input type="checkbox" /><span>ZolStar</span>                    
                    </label>
                </div>
            </div> -->
            <div class="form-controls">
                <button class="button-2">Cancel</button>
                <button type="submit">Create</button>
            </div>
        </form>
    </div>
</template>

<script>
import axiosInstance from '../../connections/AxiosInstance.js';
import UserModel from '../../models/UserModel';

export default {

    data: function(){
        return {
            newUser: new UserModel()
        }
    },
    methods: {
    },
    computed: {
    },
    created: function(){
        axiosInstance({
            method: 'get', 
            url: 'Users/1',    
        }).then(function(response){
            console.log(response.data);
        }, function(){
            console.log(arguments);
        });
    }
}

</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";

.user-create{
    padding: 30px 40px;
    min-width: 520px;

    .checkbox-group{
        padding: $space-standard 0 0;
    }
}
</style>